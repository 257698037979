import axios, { AxiosInstance } from 'axios';

const createAxios = (): AxiosInstance =>
    axios.create({
        withCredentials: true,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        baseURL: `${process.env.REACT_APP_BACKEND_HOST}`,
        headers: {
            Accept: 'application/json',
        },
    });

export default createAxios;
