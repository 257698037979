import styled from 'styled-components';

const BlankSlate = styled.div`
    margin-top: 25px;
    font-weight: bold;
    font-size: 21px;
    color: #888;
`;

export default BlankSlate;
