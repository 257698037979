/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { Button } from '../components/button';
import { Card, CardTitle, CardWrapper } from '../components/card';
import Input from '../components/input';
import Label from '../components/label';
import NavLayout from '../components/nav-layout';
import Spinner from '../components/spinner';
import { ListingCsvFormInputs } from '../types/form-input';
import { useSubmitCsv } from '../hooks/listing-keyword';

const SubmitButton = styled(Button)`
    padding: 12px 30px;
`;

const FormWrapper = styled.div`
    margin-top: 25px;
`;

const RpstLabel = styled(Label)`
    margin-bottom: 50px;
`;


const ListingRegisterCsvPage: FC = () => {
    const {
        register,
        handleSubmit,
    } = useForm<ListingCsvFormInputs>({});

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitCsv = useSubmitCsv(setIsSubmitting);

    return (
        <NavLayout
            breadcrumb={
                <Breadcrumb>
                    <BreadcrumbItem>リスティング</BreadcrumbItem>
                    <BreadcrumbItem>CSV登録</BreadcrumbItem>
                </Breadcrumb>
            }
        >
            <CardWrapper>
                <Card>
                    <CardTitle>CSV登録</CardTitle>
                {isSubmitting ? (
                    <Spinner />
                ) : (
                    <FormWrapper>
                        <form onSubmit={handleSubmit(onSubmitCsv)}>
                            <RpstLabel>
                                <Input
                                    type="file"
                                    accept=".csv"
                                    {...register('csv')}
                                />
                            </RpstLabel>
                            <SubmitButton color="blue">登録</SubmitButton>
                        </form>
                    </FormWrapper>
                )}
                </Card>
            </CardWrapper>
        </NavLayout>
    );
};

export default ListingRegisterCsvPage;