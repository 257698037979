/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { Button } from '../components/button';
import { Card, CardTitle, CardWrapper } from '../components/card';
import Input from '../components/input';
import Label from '../components/label';
import NavLayout from '../components/nav-layout';
import Spinner from '../components/spinner';
import { ListingFormInputs } from '../types/form-input';
import ErrorMessage from '../components/error-message';
import { useSubmitWord } from '../hooks/listing-keyword';

const SubmitButton = styled(Button)`
    padding: 12px 30px;
`;

const FormWrapper = styled.div`
    margin-top: 25px;
`;

const RpstLabel = styled(Label)`
    margin-bottom: 50px;
`;

const listingSchema = yup.object().shape({
    word: yup.string().required().label('ワード'),
});

const ListingRegisterPage: FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ListingFormInputs>({
        resolver: yupResolver(listingSchema),
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitWord = useSubmitWord(setIsSubmitting);

    return (
        <NavLayout
            breadcrumb={
                <Breadcrumb>
                    <BreadcrumbItem>リスティング</BreadcrumbItem>
                    <BreadcrumbItem>ワード登録</BreadcrumbItem>
                </Breadcrumb>
            }
        >
            <CardWrapper>
                <Card>
                    <CardTitle>ワード登録</CardTitle>
                {isSubmitting ? (
                    <Spinner />
                ) : (
                    <FormWrapper>
                        <form onSubmit={handleSubmit(onSubmitWord)}>
                            <RpstLabel>
                                <Input
                                    type="text"
                                    placeholder="ワードを入力してください"
                                    {...register('word')}
                                />
                                <ErrorMessage>
                                    {errors.word?.message}
                                </ErrorMessage>
                            </RpstLabel>
                            <SubmitButton color="blue">登録</SubmitButton>
                        </form>
                    </FormWrapper>
                  )}
                </Card>
            </CardWrapper>
        </NavLayout>
    );
};

export default ListingRegisterPage;