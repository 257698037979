import { ComponentProps, FC } from 'react';
import styled from 'styled-components';

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableHeader = styled.th`
    border-bottom: 1px solid #d8d8d8;
    text-align: left;
    padding: 17px 17px 17px 0;
    color: #524cf0;
    font-weight: bold;
    font-size: 14px;
`;

export const TableHeadWithNoBorder = styled(TableHeader)`
    border: none;
    color: #524cf0;
`;

export const TableHead = styled.thead``;

export const TableData = styled.td`
    border-bottom: 1px solid #d8d8d8;
    text-align: left;
    padding: 16px 16px 16px 0;
    color: #757577;
    font-size: 14px;
`;

export const TableRow = styled.tr``;

export const TableBody = styled.tbody``;

export const Table: FC<ComponentProps<typeof StyledTable>> = ({ children }) => (
    <>
        <StyledTable>{children}</StyledTable>
    </>
);

export const TableWrapper = styled.div`
    margin-top: 25px;
    margin-bottom: 32px;
`;
