import { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './global-style';
import ListingIndexPage from './pages/listing-index';
import ListingRegisterPage from './pages/listing-register';
import ListingRegisterCsvPage from './pages/listing-register-csv';
import ListingResultPage from './pages/listing-result';
import Login from './pages/login';
import Spinner from './components/spinner';
import useLogin from './hooks/login';

const Wrapper = styled.main`
    height: 100%;
`;

const App: FC = () => {
    const [isLoggedIn, isLoading, initialized] = useLogin();

    if (!initialized || isLoading) {
        return <Spinner />;
    }

    if (!isLoggedIn) {
        return (
            <>
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route path="/*" exact>
                        <Redirect to="/login" />
                    </Route>
                </Switch>
                <GlobalStyle />
            </>
        );
    }

    return(
        <Wrapper>
            <Switch>
                <Route path="/login" exact>
                    <Redirect to="/" />
                </Route>
                <Route path="/" exact>
                    <Redirect to="/listings/index" />
                </Route>
                <Route path="/listings" exact>
                    <Redirect to="/listings/index" />
                </Route>
                <Route path="/listings/index" exact>
                    <ListingIndexPage />
                </Route>
                <Route path="/listings/new" exact>
                    <ListingRegisterPage />
                </Route>
                <Route path="/listings/csv/new" exact>
                    <ListingRegisterCsvPage />
                </Route>
                <Route path="/listings/:id/result" exact>
                    <ListingResultPage />
                </Route>
            </Switch>
            <GlobalStyle />
            <ToastContainer />
        </Wrapper>
    )
};
export default App;
