import styled from 'styled-components';

const Label = styled.label`
    width: 25%;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 12px;
    margin-right: 20px;
    vertical-align: top;
`;

export default Label;