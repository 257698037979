import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.nav`
    height: 100%;
    padding: 0 18px;
    background: linear-gradient(rgba(23, 24, 42, 1), rgba(31, 28, 55, 1));
    position: fixed;
`;

const NavHeader = styled.header`
    border-bottom: solid rgba(255, 255, 255, 0.52) 2px;
    margin-bottom: 38px;
`;

const NavTitle = styled.p`
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    line-height: 2.5;
    text-align: center;
`;

const Menu = styled.ul`
    list-style: none;
`;

const MenuItem = styled.li`
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
`;

const Link = styled(NavLink)`
    display: block;
    color: rgb(184, 184, 184);
    text-decoration: none;
    font-size: 20px;
    height: 40px;
    padding: 20px 50px 30px 40px;
    &.active {
        color: rgb(181, 76, 240);
    }
    &.active + .sub-link {
        display: block;
    }
    &:hover:not(.active) {
        color: #fff;
    }
`;


const GlobalNav: FC = () => (
    <Wrapper>
        <NavHeader>
            <NavTitle>BRC-LIST</NavTitle>
        </NavHeader>
        <Menu>
            <MenuItem>
                <Link to="/listings/index">ワード一覧</Link>
                <Link to="/listings/new">ワード登録</Link>
                <Link to="/listings/csv/new">CSV登録</Link>
            </MenuItem>
        </Menu>
    </Wrapper>
);

export default GlobalNav;
