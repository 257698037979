/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState, useEffect,Dispatch,SetStateAction,useCallback, } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import createAxios from '../axios';
import { Keyword, ListingKeywordApiResponse, ListingFormInputs, ListingCsvFormInputs,ListingSearchApiResponse } from '../types/types';

export const useListingKeywords = (
    currentPage: number,
): readonly [ListingKeywordApiResponse | undefined, boolean] => {
    const [isLoading, setIsLoading] = useState(false);
    const [keywords, setKeywords] = useState<ListingKeywordApiResponse>();

    useEffect(() => {
        setIsLoading(true);
        createAxios()
            .get<ListingKeywordApiResponse>(`/api/listings?page=${currentPage}`)
            .then((response) => {
                setKeywords(response.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [currentPage]);

    return [keywords, isLoading] as const;
};

export const useSearchKeywords = (
  searchedKeywords: Keyword[] | undefined,
  setSearchedKeywords: Dispatch<SetStateAction<Keyword[] | undefined>>,
  isSubmitting: boolean,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
): ((formData: { word: string }) => void) => {
  const onSearch = useCallback(
      (formData: { word: string }) => {
          setIsSubmitting(true);
          createAxios()
              .post<Keyword[]>('/api/listings/search', {
                  word: formData.word,
              })
              .then((response) => {
                  setSearchedKeywords(response.data);
                  sessionStorage.setItem('searchWord', formData.word);
                  setIsSubmitting(false);
              })
              .catch(() => {
                  setIsSubmitting(false);
              });
      },
      [setSearchedKeywords, setIsSubmitting],
  );

  return onSearch;
};

export const useSubmitWord = (
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
): ((formData: ListingFormInputs) => void) => {
  const history = useHistory();

  const onSubmitWord = useCallback(
      (formData: ListingFormInputs) => {
          setIsSubmitting(true);
          createAxios()
              .post<void>('/api/listings/new', {
                  word: formData.word,
              })
              .then(() => {
                  setIsSubmitting(false);
                  history.push('/');
                  toast.info('ワードの登録が完了しました');
              })
              .catch(() => {
                  setIsSubmitting(false);
                  toast.error('ワードの登録に失敗しました');
              });
      },
      [history, setIsSubmitting],
  );

  return onSubmitWord;
};

export const useSubmitCsv = (
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
): ((formData: ListingCsvFormInputs) => void) => {
  const history = useHistory();

  const onSubmitCsv = useCallback(
      (formData: ListingCsvFormInputs) => {
          const formsData = new FormData();
          formsData.append('csv_file', formData.csv[0]);
          setIsSubmitting(true);
          createAxios()
              .post<void>('/api/listings/csv/new', formsData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })
              .then(() => {
                  setIsSubmitting(false);
                  history.push('/');
                  toast.info('ワードの登録が完了しました');
              })
              .catch(() => {
                  setIsSubmitting(false);
                  toast.error('ワードの登録に失敗しました');
              });
      },
      [history, setIsSubmitting],
  );

  return onSubmitCsv;
};

export const useListingKeywordDetail = (
  keywordId: string,
): readonly [Keyword | undefined, boolean] => {
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState<Keyword>();

  useEffect(() => {
      setIsLoading(true);
      createAxios()
          .get<Keyword>(`/api/listings/${keywordId}`)
          .then((response) => {
              setKeyword(response.data);
              setIsLoading(false);
          })
          .catch(() => {
              setIsLoading(false);
          });
  }, [keywordId]);

  return [keyword, isLoading] as const;
};

export const useListingSearchResult = (
  currentPage: number,
  keywordId: string,
): readonly [ListingSearchApiResponse | undefined, boolean] => {
  const [isLoad, setIsLoad] = useState(false);
  const [results, setResult] = useState<ListingSearchApiResponse>();

  useEffect(() => {
      setIsLoad(true);
      createAxios()
          .get<ListingSearchApiResponse>(`/api/listings/${keywordId}/result?page=${currentPage}`)
          .then((response) => {
              setResult(response.data);
              setIsLoad(false);
          })
          .catch(() => {
              setIsLoad(false);
          });
  }, [currentPage,keywordId]);

  return [results, isLoad] as const;
};