/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC, useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import NavLayout from '../components/nav-layout';
import BlankSlate from '../components/blankslate';
import { Card, CardTitle, CardWrapper } from '../components/card';
import Spinner from '../components/spinner';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import {
    Table,
    TableBody,
    TableData,
    TableHead,
    TableHeader,
    TableRow,
    TableWrapper,
} from '../components/table';
import { Button } from '../components/button';
import SearchForm from '../components/search-form';
import { useListingKeywords,useSearchKeywords } from '../hooks/listing-keyword';
import { Keyword } from '../types/types';
import { Pager } from '../components/pager';

const LinkWrapper = styled(NavLink)`
    display: block;
    text-decoration: none;
    &:hover:not(.active) {
        color: red;
    }
`;

const KeywordList: FC<{ keyword: Keyword }> = ({ keyword }) => {
    const date = keyword.updated_at;
    const strDate = date.toLocaleString();
    const formatedDate = strDate.slice(0,10);

    return(
        <TableRow>
            <TableData>{keyword.word}</TableData>
            <TableData>{keyword.status}</TableData>
            <TableData>{keyword.name}</TableData>
            <TableData>{formatedDate}</TableData>
            <TableData>
                <LinkWrapper to={`/listings/${keyword.keyword_id}/result`}>結果</LinkWrapper>
            </TableData>
        </TableRow>
    )
};

const ListingIndexPage: FC = () => {
    const defaultPageNum = sessionStorage.getItem('wordPageNum')
        ? Number(sessionStorage.getItem('wordPageNum'))
        : 1;
    const [currentPage, setCurrentPage] = useState(defaultPageNum);
    const [keywords, isLoading] = useListingKeywords(currentPage);

    const [searchedKeywords, setSearchedKeywords] = useState<Keyword[]>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const searchKeywords = useSearchKeywords(
        searchedKeywords,
        setSearchedKeywords,
        isSubmitting,
        setIsSubmitting,
    );

    const defaultSearchWord = sessionStorage.getItem('searchWord');
    const [showEveryKeywords, setShowEveryKeywords] = useState<boolean>(
        !defaultSearchWord,
    );
    useEffect(() => {
        if (defaultSearchWord) {
            searchKeywords({ word: defaultSearchWord });
        }
    }, [defaultSearchWord, searchKeywords]);

    return (
        <NavLayout
            breadcrumb={
                <Breadcrumb>
                    <BreadcrumbItem>リスティング</BreadcrumbItem>
                    <BreadcrumbItem>登録ワード一覧</BreadcrumbItem>
                </Breadcrumb>
            }
        >
            <CardWrapper>
                <Card>
                    <CardTitle>ワード一覧</CardTitle>
                    {!showEveryKeywords && (
                        <Button
                            type="click"
                            size="small"
                            color="black"
                            onClick={() => {
                                sessionStorage.removeItem('searchWord');
                                setShowEveryKeywords(true);
                            }}
                        >
                            全てのワードを表示
                        </Button>
                    )}
                    <SearchForm
                        onSubmit={(formData) => {
                            setShowEveryKeywords(false);
                            searchKeywords(formData);
                        }}
                        defaultSearchWord={defaultSearchWord || undefined}
                    />
                    {isLoading ? (
                        <Spinner />
                    ) : showEveryKeywords && keywords && keywords?.data.length > 0 ? (
                        <>
                            <TableWrapper>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableHeader>ワード</TableHeader>
                                            <TableHeader>
                                                ステータス
                                            </TableHeader>
                                            <TableHeader>登録者</TableHeader>
                                            <TableHeader>登録日</TableHeader>
                                            <TableHeader>結果</TableHeader>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {keywords?.data.map((value) => (
                                            <KeywordList
                                                key={value.word}
                                                keyword={value}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableWrapper>
                            <Pager
                                currentPage={currentPage}
                                totalPages={keywords.last_page}
                                onChange={(pageNum) => {
                                    setCurrentPage(pageNum);
                                    sessionStorage.setItem(
                                        'wordPageNum',
                                        String(pageNum),
                                    );
                                }}
                            />
                        </>
                    ) : !showEveryKeywords &&
                    searchedKeywords &&
                    searchedKeywords.length > 0 ? (
                      <>
                          <TableWrapper>
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                            <TableHeader>ワード</TableHeader>
                                            <TableHeader>
                                                ステータス
                                            </TableHeader>
                                            <TableHeader>登録者</TableHeader>
                                            <TableHeader>登録日</TableHeader>
                                            <TableHeader>結果</TableHeader>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {searchedKeywords.map((value) => (
                                          <KeywordList
                                              key={value.word}
                                              keyword={value}
                                          />
                                      ))}
                                  </TableBody>
                              </Table>
                          </TableWrapper>
                      </>
                  ):(
                        <BlankSlate>ワードが見つかりませんでした。</BlankSlate>
                    )}
                </Card>
            </CardWrapper>
        </NavLayout>
    );
};
export default ListingIndexPage;
