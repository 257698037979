/* eslint-disable no-console */
import { VFC } from 'react';
import styled from 'styled-components';
import GoogleButton from 'react-google-button';
import { Card, CardTitle } from '../components/card';
import oauth2Popup from '../oauth2popup';

const Wrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.26);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
`;

const Title = styled(CardTitle)`
    margin-bottom: 40px;
`;

const onLogin = async () => {
    await oauth2Popup();
};

const Login: VFC = () => (
    <Wrapper>
        <Card>
            <Title>Start using BRC-LIST</Title>
            <GoogleButton style={{ margin: '0 auto' }} onClick={onLogin} />
        </Card>
    </Wrapper>
);

export default Login;