const oauth2Popup = (): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
        const width = 500;
        const height = 700;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const url = `${process.env.REACT_APP_BACKEND_HOST}/auth/redirect`;
        const name = 'googleOAuth2Popup';
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;
        const popupWindow = window.open(
            url,
            name,
            `height=${height},width=${width},left=${left},top=${top}`,
        );

        if (!popupWindow) throw new Error('Failed to open popup window');

        const interval = window.setInterval(() => {
            let code: string | null = null;
            try {
                const currentUrl = popupWindow.location.href;
                const params = new URL(currentUrl).searchParams;
                code = params.get('code');
                if (code) {
                    popupWindow.close();
                    resolve(true);
                }
            } catch (error) {
                // surpress accessing cross-origin frame error
            } finally {
                if (!popupWindow || popupWindow.closed) {
                    window.clearInterval(interval);
                    // eslint-disable-next-line no-unused-expressions
                    code === null && resolve(false);
                    window.location.href = '/';
                }
            }
        }, 500);
    });

export default oauth2Popup;