import { useCallback, useLayoutEffect, useState } from 'react';
import createAxios from '../axios';

interface User {
    id: number;
    name: string;
}
const useLogin = (): readonly [boolean, boolean, boolean] => {
    const [initialized, setInitialized] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const tryLogin = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data } = await createAxios().get<User>('/api/me');
            setIsLoggedIn(!!data);
        } finally {
            setIsLoading(false);
        }
    }, []);
    useLayoutEffect(() => {
        void (async () => {
            try {
                await createAxios().get('/sanctum/csrf-cookie');
                await tryLogin();
            } finally {
                setInitialized(true);
            }
        })();
    }, [tryLogin]);

    return [isLoggedIn, isLoading, initialized] as const;
};

export default useLogin;