import {
    library,
    IconDefinition,
    IconProp as FAIconProp,
} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fas as unknown as IconDefinition);
library.add(fab as unknown as IconDefinition);

export type IconProp = FAIconProp;
const Icon = FontAwesomeIcon;
export default Icon;