import styled from 'styled-components';

export const Card = styled.div<{ size?: 'large' | 'medium' }>`
    border-radius: 20px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    padding: ${({ size }) => (size === 'medium' ? '32px 48px' : '48px 64px')};
    background-color: #fff;
`;

export const CardHeader = styled.header`
    margin-bottom: 48px;
    display: flex;
`;

export const CardContent = styled.div``;

export const CardTitle = styled.p`
    color: darkblue;
    font-weight: bold;
    font-size: 21px;
    line-height: 45px;
    height: 45px;
`;

export const CardWrapper = styled.div`
    margin-bottom: 32px;
`;
