/* eslint-disable react/require-default-props */
import { ComponentProps, FC } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from './spinner';

const colors = {
    blue: 'blue',
    danger: '#dc3545',
    secondary: '#83ebe0',
    black: 'black',
};

type Color = keyof typeof colors;

const StyledButton = styled.button<{
    color?: Color;
    size?: 'medium' | 'small';
}>`
    background-color: ${({ color }) => colors[color || 'blue']};
    border-radius: 12px;
    border: none;
    color: #fff;
    padding: ${({ size }) => (size === 'small' ? '10px 20px' : '16px 32px')};
    text-decoration: none;
    line-height: 100%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    &:disabled {
        opacity: 0.4;
    }
`;

const SpinnerWrapper = styled.span`
    margin-right: 10px;
`;

export type ButtonProps = ComponentProps<typeof StyledButton> & {
    isLoading?: boolean;
    color?: Color;
    to?: string;
};

export const Button: FC<ButtonProps> = ({ children, isLoading, ...props }) => {
    // eslint-disable-next-line react/destructuring-assignment
    const component = props.to ? Link : 'button';

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledButton as={component} {...props}>
            {isLoading && (
                <SpinnerWrapper theme>
                    <Spinner />
                </SpinnerWrapper>
            )}
            {children}
        </StyledButton>
    );
};