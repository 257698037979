/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import NavLayout from '../components/nav-layout';
import BlankSlate from '../components/blankslate';
import { Card, CardTitle } from '../components/card';
import Spinner from '../components/spinner';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import {
    Table,
    TableBody,
    TableData,
    TableHead,
    TableRow,
    TableWrapper,
    TableHeadWithNoBorder,
} from '../components/table';
import { useListingKeywordDetail,useListingSearchResult } from '../hooks/listing-keyword';
import { Pager } from '../components/pager';

const CardWrapper = styled.div`
    margin-bottom: 32px;
`;


const ListingResultPage: FC = () => {
  // eslint-disable-next-line camelcase
  const params = useParams<{ id: string }>();
  const keywordId = params.id;
  const [keyword, isLoading] = useListingKeywordDetail(keywordId);
  const [currentPage, setCurrentPage] = useState(1);
  const [results, isLoad] = useListingSearchResult(currentPage,keywordId);


  return (
      <NavLayout
          breadcrumb={
              <Breadcrumb>
                  <BreadcrumbItem>Listing</BreadcrumbItem>
                  <BreadcrumbItem>チェック結果</BreadcrumbItem>
              </Breadcrumb>
          }
      >
          <CardWrapper>
              <Card>
                  <CardTitle>チェック結果</CardTitle>
                  {isLoading ? (
                      <Spinner />
                  ) : keyword ? (
                      <>
                          <TableWrapper>
                            <Table>
                              <TableHead>
                                  <TableRow>
                                      <TableHeadWithNoBorder>ワード</TableHeadWithNoBorder>
                                      <TableHeadWithNoBorder>ステータス</TableHeadWithNoBorder>
                                      <TableHeadWithNoBorder>登録者</TableHeadWithNoBorder>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  <TableRow>
                                      <TableData>{keyword.word}</TableData>
                                      <TableData>{keyword.status}</TableData>
                                      <TableData>{keyword.name}</TableData>
                                  </TableRow>
                              </TableBody>
                            </Table>
                          </TableWrapper>
                      </>
                  ) : (
                      <BlankSlate>
                          ワードが見つかりませんでした。
                      </BlankSlate>
                  )}
                  {isLoad ? (
                      <Spinner />
                  ) : results && results?.data.length > 0 ? (
                      <>
                          <TableWrapper>
                            <Table>
                              <TableHead>
                                  <TableRow>
                                      <TableHeadWithNoBorder>URL</TableHeadWithNoBorder>
                                      <TableHeadWithNoBorder>タイトル</TableHeadWithNoBorder>
                                      <TableHeadWithNoBorder>概要</TableHeadWithNoBorder>
                                      <TableHeadWithNoBorder>電話番号</TableHeadWithNoBorder>
                                      <TableHeadWithNoBorder>取得日時</TableHeadWithNoBorder>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                      {results?.data.map((result) => (
                                          <TableRow key={result.id}>
                                            <TableData>{result.url}</TableData>
                                            <TableData>{result.title}</TableData>
                                            <TableData>{result.description}</TableData>
                                            <TableData>{result.telephone_number}</TableData>
                                            <TableData>{result.updated_at}</TableData>
                                          </TableRow>
                                      ))}
                              </TableBody>
                            </Table>
                          </TableWrapper>
                          <Pager
                              currentPage={currentPage}
                              totalPages={results.last_page}
                              onChange={(pageNum) => setCurrentPage(pageNum)}
                          />
                      </>
                  ) : (
                      <BlankSlate>
                          チェック結果が見つかりませんでした。
                      </BlankSlate>
                  )}
              </Card>
          </CardWrapper>
      </NavLayout>
  );
};
export default ListingResultPage;
