/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Icon from './icon';

export interface PagerProps {
    totalPages: number;
    currentPage: number;
    onChange: (pageNum: number) => void;
}

const usePages = (props: PagerProps) =>
    useMemo(() => {
        const firstPage = Math.max(1, props.currentPage - 2);
        const lastPage = Math.min(props.totalPages, props.currentPage + 2);
        const pageNums = [];
        for (let i = firstPage; i <= lastPage; i++) {
            pageNums.push(i);
        }
        const hasPrev = props.currentPage > 1;
        const hasNext = props.currentPage < props.totalPages;

        return { hasPrev, hasNext, pageNums };
    }, [props.currentPage, props.totalPages]);

const List = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
`;

const ListItem = styled.li``;

const Button = styled.button<{ active?: boolean }>`
    width: 32px;
    height: 32px;
    border-radius: 0;
    border: none;
    color: #757577;
    background-color: ${({ active }) =>
        active ? 'rgb(145, 197, 232)' : 'transparent'};
    color: ${({ active }) => (active ? '#fff' : '#757577')};
    font-size: 14px;
    ${({ active }) =>
        !active
            ? `&:disabled {
          opacity: 0.3;
        }`
            : ''}
`;

const PagerItem = (props: {
    pageNum: number;
    active: boolean;
    onClick: (pageNum: number) => void;
}) => {
    const onClick = useCallback(() => props.onClick(props.pageNum), [props]);

    return (
        <ListItem>
            <Button
                active={props.active}
                onClick={onClick}
                disabled={props.active}
            >
                {props.pageNum}
            </Button>
        </ListItem>
    );
};

export const Pager: FC<PagerProps> = (props) => {
    const { hasNext, hasPrev, pageNums } = usePages(props);

    const onClickButton = useCallback(
        (pageNum: number) => props.onChange(pageNum),
        [props],
    );

    const onClickNext = useCallback(
        () => props.onChange(props.currentPage + 1),
        [props],
    );

    const onClickPrev = useCallback(
        () => props.onChange(props.currentPage - 1),
        [props],
    );

    const onClickFirst = useCallback(() => props.onChange(1), [props]);

    const onClickLast = useCallback(
        () => props.onChange(props.totalPages),
        [props],
    );

    return (
        <nav>
            <List>
                <ListItem>
                    <Button
                        disabled={props.currentPage === 1}
                        onClick={onClickFirst}
                    >
                        <Icon icon="chevron-left" />
                        <Icon icon="chevron-left" />
                    </Button>
                </ListItem>

                <ListItem>
                    <Button disabled={!hasPrev} onClick={onClickPrev}>
                        <Icon icon="chevron-left" />
                    </Button>
                </ListItem>

                {pageNums.map((n) => (
                    <PagerItem
                        key={n}
                        pageNum={n}
                        active={n === props.currentPage}
                        onClick={onClickButton}
                    />
                ))}

                <ListItem>
                    <Button disabled={!hasNext} onClick={onClickNext}>
                        <Icon icon="chevron-right" />
                    </Button>
                </ListItem>

                <ListItem>
                    <Button
                        disabled={props.currentPage === props.totalPages}
                        onClick={onClickLast}
                    >
                        <Icon icon="chevron-right" />
                        <Icon icon="chevron-right" />
                    </Button>
                </ListItem>
            </List>
        </nav>
    );
};
