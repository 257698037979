import styled from 'styled-components';

export const FormWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const FormTitle = styled.p`
    font-weight: bold;
    font-size: 21px;
    line-height: 45px;
    height: 45px;
`;