/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormWrapper } from './form';
import Input from './input';
import Label from './label';
import { Button } from './button';

interface searchFormProps {
    onSubmit: (formValue: { word: string }) => void;
    // eslint-disable-next-line react/require-default-props
    defaultSearchWord?: string;
}

const searchFormSchema = yup.object().shape({
    word: yup.string().required().label('検索ワード'),
});

const SearchForm: FC<searchFormProps> = ({ onSubmit, defaultSearchWord }) => {
    const { register, handleSubmit } = useForm<{ word: string }>({
        resolver: yupResolver(searchFormSchema),
    });

    return (
        <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label>
                    <Input
                        type="text"
                        defaultValue={defaultSearchWord}
                        placeholder="ワードを入力してください"
                        {...register('word')}
                    />
                </Label>
                <Button type="submit" size="small">
                    検索
                </Button>
            </form>
        </FormWrapper>
    );
};

export default SearchForm;