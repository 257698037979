import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.nav`
    color: #b8b8b8;
`;

const List = styled.ul`
    display: flex;
    justify-content: center;
    justify-items: center;
    height: 100%;
`;

const Item = styled.li`
    display: flex;
    align-content: center;
    align-items: center;
`;

const ContentArea = styled.div`
    margin-right: 18px;
`;

export const BreadcrumbItem: FC = ({ children }) => (
    <Item>
        <ContentArea>{children}</ContentArea>
    </Item>
);

export const Breadcrumb: FC = ({ children }) => (
    <Wrapper>
        <List>{children}</List>
    </Wrapper>
);
