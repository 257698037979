import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import GlobalNav from './global-nav';

const Wrapper = styled.div`
    display: grid;
    grid-template:
        'nav header' auto
        'nav content' 1fr / 240px 1fr;
    height: 100%;
    background-color: #fbfbfd;
`;

const NavArea = styled.aside`
    grid-area: nav;
`;

const HeaderArea = styled.header`
    height: 66px;
    border-bottom: solid rgba(190, 190, 190, 0.24) 2px;
    grid-area: header;
    display: flex;
    padding: 9px 20px 9px 64px;
    align-content: center;
`;

const ContentArea = styled.main`
    grid-area: content;
    padding: 64px;
`;

export interface NavLayoutProps {
    breadcrumb: ReactNode;
}

const NavLayout: FC<NavLayoutProps> = ({ breadcrumb, children }) => (
    <Wrapper>
        <NavArea>
            <GlobalNav />
        </NavArea>
        <HeaderArea>{breadcrumb}</HeaderArea>
        <ContentArea>{children}</ContentArea>
    </Wrapper>
);

export default NavLayout;
